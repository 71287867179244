import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { BlogState, GetBlogAuthor } from 'src/app/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-blog-author-page',
	templateUrl: './blog-author-page.component.html',
	styleUrls: ['./blog-author-page.component.scss']
})
export class BlogAuthorPageComponent implements OnInit {
	author: any;

	@Select(BlogState.getAuthor) author$: Observable<any>;

	constructor(private store: Store, private route: ActivatedRoute, private titleService: Title) { }

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			const authorName = params.get('name');
			this.store.dispatch(new GetBlogAuthor(authorName));
		});

		this.author$.subscribe(author => {
			if (!author) return;
			this.titleService.setTitle(author.name);
			this.author = author;
		});
	}
}
