import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetHomeContent, GetHomeContentSuccess } from './home.actions';
import { HomeService } from '../services/home.service';
import produce from 'immer';

export class HomeStateModel {
	image: string;
	strapline: string;
	topLinkText: string;
	topLinkUrl: string;
	topLinkButtonText: string;
	topText: string;
	topTitle: string;
	topText2: string;
	topImage: string;
	centralImage: string;
	centreText: string;
	bottomText: string;
	bottomImage: string;
	bottomTitle: string;
	bottomTitle2: string;
	bottomText2: string;
	mainTitle: string;
	// comingSoonTitle: string;
	// comingSoonContent: string;
}

@State<HomeStateModel>({
	name: 'home',
	defaults: {
		image: '',
		strapline: '',
		topLinkText: '',
		topLinkUrl: '',
		topLinkButtonText: '',
		topTitle: '',
		topText: '',
		topText2: '',
		topImage: '',
		centralImage: '',
		centreText: '',
		bottomText: '',
		bottomImage: '',
		bottomTitle: '',
		bottomTitle2: '',
		bottomText2: '',
		mainTitle: '',
		// comingSoonTitle: '',
		// comingSoonContent: ''
	},
})
export class HomeState {
	constructor(private homeService: HomeService) {}

	@Selector()
	static getHomeContent(state: HomeStateModel) {
		return state;
	}

	@Action(GetHomeContent)
	onGetHomeContent({ dispatch }: StateContext<HomeStateModel>) {
		this.homeService.getHomeContent().subscribe(content => {
			dispatch(new GetHomeContentSuccess(content));
		});
	}

	@Action(GetHomeContentSuccess)
	onGetHomeContentSuccess(
		{ patchState, getState }: StateContext<HomeStateModel>,
		{ homeContent }: GetHomeContentSuccess
	) {
		patchState(
			produce(getState(), draft => {
				draft.image = homeContent.image;
				draft.strapline = homeContent.strapline;
				draft.topText = homeContent.topText;
				draft.topLinkButtonText = homeContent.topLinkButtonText;
				draft.topLinkText = homeContent.topLinkText;
				draft.topLinkUrl = homeContent.topLinkUrl;
				draft.topTitle = homeContent.topTitle;
				draft.topText2 = homeContent.topText2;
				draft.topImage = homeContent.topImage;
				draft.centralImage = homeContent.centralImage;
				draft.centreText = homeContent.centreText;
				draft.bottomText = homeContent.bottomText;
				draft.bottomImage = homeContent.bottomImage;
				draft.bottomTitle = homeContent.bottomTitle;
				draft.bottomTitle2 = homeContent.bottomTitle2;
				draft.bottomText2 = homeContent.bottomText2;
				draft.mainTitle = homeContent.mainTitle;
				// draft.comingSoonTitle = homeContent.comingSoonTitle;
				// draft.comingSoonContent = homeContent.comingSoonContent;
			})
		);
	}
}
