import { Title } from '@angular/platform-browser';
import { GetContactUsContent } from './../../store/contact-us.actions';
import { Observable } from 'rxjs';
import { ContactUsState } from './../../store/contact-us.state';
import { Select, Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-contact-us-page',
	templateUrl: './contact-us-page.component.html',
	styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit {
	content: any;
	success: boolean;

	@Select(ContactUsState.getContent)
	content$: Observable<any>;

	@Select(ContactUsState.getSuccess)
	success$: Observable<boolean>;

	constructor(private store: Store, private titleService: Title) {}

	ngOnInit() {
		this.titleService.setTitle('Contact Us');
		this.store.dispatch(new GetContactUsContent()).subscribe(() => {
			this.content$.subscribe(content => {
				if (!content) return;

				this.content = content;
			});
		});

		this.success$.subscribe(s => {
			this.success = s;
		});
	}
}
