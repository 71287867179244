import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SiteService {
	constructor(private restangular: Restangular) {}

	getMenu(): Observable<any[]> {
		return this.restangular.one('menu').get();
	}

	getFooter(): Observable<any> {
		return this.restangular.one('footer').get();
	}
}
