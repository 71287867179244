import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-man-blogs-page',
	templateUrl: './man-blogs-page.component.html',
	styleUrls: ['./man-blogs-page.component.scss'],
})
export class MansBlogsPageComponent implements OnInit {

	constructor(private titleService: Title) { }

	ngOnInit() {
		this.titleService.setTitle('Man Blog');
	}
}
