import produce from 'immer';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { BlogService } from '../services/blog.service';
import {
	GetBlogList,
	GetBlogListSuccess,
	GetBlogPost,
	GetBlogPostSuccess,
	GetBlogAuthor,
	GetBlogAuthorSuccess,
	SetBlogFilter
} from './blog.actions';
import * as moment from 'moment';

export class BlogStateModel {
	blogs: Array<any>;
	manBlogs: Array<any>;
	post: any;
	author: any;
	currentFilter: any;
	filtered: Array<any>;
}

const stateDefaults = {
	blogs: [],
	manBlogs: [],
	post: null,
	author: null,
	currentFilter: null,
	filtered: []
};

@State<BlogStateModel>({
	name: 'blog',
	defaults: stateDefaults,
})
export class BlogState {
	constructor(private blogService: BlogService) { }

	@Selector()
	static getSummaries(state: BlogStateModel) {
		const summaries = state.blogs.map(summary => {
			return {
				...summary,
				date: moment(summary.publishedDate).format('DD MMM YYYY'),
				hasImage: summary.images !== null,
			};
		});

		const manSummaries = state.manBlogs.map(summary => {
			return {
				...summary,
				date: moment(summary.publishedDate).format('DD MMM YYYY'),
				hasImage: summary.images !== null,
			};
		});

		return {
			ladyBlogs: summaries,
			manBlogs: manSummaries
		};
	}

	@Selector()
	static getRegularAuthors(state: BlogStateModel) {
		const unique = [];
		const uniqueMen = [];

		state.blogs.map(blog => {
			if (blog.authorLink) {
				const current = unique.find(i => {
					return i.name === blog.author && i.link === blog.authorLink;
				});

				if (!current) {
					unique.push({ name: blog.author, link: blog.authorLink });
				}
			}
		});

		state.manBlogs.map(blog => {
			if (blog.authorLink) {
				const current = uniqueMen.find(i => {
					return i.name === blog.author && i.link === blog.authorLink;
				});

				if (!current) {
					uniqueMen.push({ name: blog.author, link: blog.authorLink });
				}
			}
		});

		return {
			ladyAuthors: unique,
			manAuthors: uniqueMen
		}
	}

	@Selector()
	static getFiltered(state: BlogStateModel) {
		const f = state.currentFilter;
		let filteredSummaries;

		if (f.type === 'Tag') {
			filteredSummaries = state.blogs.filter(summary => {
				return summary.tags && summary.tags.indexOf(f.filter) > -1;
			});
		} else {
			filteredSummaries = state.blogs.filter(summary => {
				return summary.authorLink && summary.authorLink.indexOf(f.filter) > -1;
			});
		}

		const summaries = filteredSummaries.map(summary => {
			return {
				...summary,
				date: moment(summary.publishedDate).format('DD MMM YYYY'),
				hasImage: summary.images !== null,
			};
		});

		return summaries;
	}

	@Selector()
	static getAuthor(state: BlogStateModel) {
		return {
			...state.author,
			hasImage: state.author.images !== null,
		};
	}

	@Selector()
	static getSelectedBlogPost(state: BlogStateModel) {
		return state.post;
	}

	@Selector()
	static getTags(state: BlogStateModel) {
		const arr = new Array<any>();

		if (!state.blogs) return;

		const tags = state.blogs
			.map(blog => {
				return blog.tags;
			})
			.reduce((a, b) => {
				return a.concat(b);
			})
			.reduce((prev, curr) => {
				const t = arr.find(i => i.tag === curr);

				if (!t) {
					arr.push({ tag: curr, count: 1 });
				} else {
					t.count++;
				}

				return arr;
			})
			.sort((a, b) => {
				return b.count - a.count;
			});

		const spliced = tags.length > 25 ? tags.splice(0, 25) : tags;

		return spliced.map(item => {
			return item.tag;
		});
	}

	@Selector()
	static getManTags(state: BlogStateModel) {
		const arr = new Array<any>();

		if (!state.manBlogs) return;

		const tags = state.manBlogs
			.map(blog => {
				return blog.tags;
			})
			.reduce((a, b) => {
				return a.concat(b);
			})
			.reduce((prev, curr) => {
				const t = arr.find(i => i.tag === curr);

				if (!t) {
					arr.push({ tag: curr, count: 1 });
				} else {
					t.count++;
				}

				return arr;
			})
			.sort((a, b) => {
				return b.count - a.count;
			});

		const spliced = tags.length > 25 ? tags.splice(0, 25) : tags;

		return spliced.map(item => {
			return item.tag;
		});
	}

	@Action(GetBlogList)
	onGetBlogList({ dispatch }: StateContext<BlogStateModel>) {
		this.blogService.getBlogList().subscribe(blogs => {
			dispatch(new GetBlogListSuccess(blogs));
		});
	}

	@Action(GetBlogListSuccess)
	onGetBlogListSuccess({ getState, patchState }: StateContext<BlogStateModel>, { blogs }: GetBlogListSuccess) {
		patchState(
			produce(getState(), draft => {
				draft.post = null;
				draft.blogs = blogs;
				draft.manBlogs = blogs.manBlog;
				draft.blogs = blogs.ladyBlog;
			})
		);
	}

	@Action(GetBlogPost)
	onGetBlogPost({ dispatch }: StateContext<BlogStateModel>, { name }: GetBlogPost) {
		this.blogService.getBlogPost(name).subscribe(blog => {
			dispatch(new GetBlogPostSuccess(blog));
		});
	}

	@Action(GetBlogPostSuccess)
	onGetBlogPostSuccess({ patchState, getState }: StateContext<BlogStateModel>, { blogPost }: GetBlogPostSuccess) {
		patchState(
			produce(getState(), draft => {
				draft.post = blogPost;
				draft.post.date = moment(blogPost.publishedDate).format('DD MMM YYYY');
				draft.post.hasImage = blogPost.images !== null;
			})
		);
	}

	@Action(GetBlogAuthor)
	onGetBlogAuthor({ dispatch }: StateContext<BlogStateModel>, { name }: GetBlogAuthor) {
		this.blogService.getBlogAuthor(name).subscribe(author => {
			dispatch(new GetBlogAuthorSuccess(author));
		});
	}

	@Action(GetBlogAuthorSuccess)
	onGetBlogAuthorSuccess({ patchState, getState }: StateContext<BlogStateModel>, { author }: GetBlogAuthorSuccess) {
		patchState(
			produce(getState(), draft => {
				draft.author = author;
			})
		);
	}

	@Action(SetBlogFilter)
	onSetBlogFilter({ patchState, getState }: StateContext<BlogStateModel>, { filterType, filter }: SetBlogFilter) {
		patchState(produce(getState(), draft => {
			draft.currentFilter = {};
			draft.currentFilter.type = filterType;
			draft.currentFilter.filter = filter;
		}));
	}
}
