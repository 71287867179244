import { MarblesTitleService } from './../../../shared/services/title.service';
import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { SectionState, GetSection } from '../../../store';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sections-page',
  templateUrl: './sections-page.component.html',
  styleUrls: ['./sections-page.component.scss']
})
export class SectionsPageComponent implements OnInit {

	sectionName: string;
	section: any;

	@Select(SectionState.getSection) section$: any;
	bob: any;

	constructor(private route: ActivatedRoute, private store: Store, private sanitizer: DomSanitizer,
		private titleService: Title, private marblesTitleService: MarblesTitleService) {}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.sectionName = params.get('name');

			this.store.dispatch(new GetSection(this.sectionName, 'sections'));

			this.section$.subscribe(section => {
				this.bob = this.sanitizer.bypassSecurityTrustHtml(section.aBitOfBlurb);
				this.titleService.setTitle(this.marblesTitleService.strip(section.title));
				this.section = section;
			});
		});
	}
}
