import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
	selector: 'app-blog-author',
	templateUrl: './blog-author.component.html',
	styleUrls: ['./blog-author.component.scss']
})
export class BlogAuthorComponent implements OnChanges {
	routerLink: string;

	@Input() blog: any;

	ngOnChanges() {
		this.routerLink = this.blog.manBlog ? '/man-blog' : '/blog';
	}
}
