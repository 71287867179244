export class GetContactUsContent {
	public static type = '[Contact Us] Get Content';
}

export class GetContactUsContentSuccess {
	public static type = '[Contact Us] Get Content Success';
	constructor(public readonly content: any) {}
}

export class SendContactUsMessage {
	public static type = '[Contact Us] Send Message';
}

export class SendContactUsMessageSuccess {
	public static type = '[Contact Us] Send Message Success';
}

export class SendContactUsMessageError {
	public static type = '[Contact Us] Send Message Error';
	constructor(public readonly error: any) {}
}

export class ContactUsResetState {
	public static type = '[Contact Us] Reset State';
}
