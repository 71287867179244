import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ContactUsService } from '../services/contact-us.service';
import {
	SendContactUsMessage,
	SendContactUsMessageSuccess,
	SendContactUsMessageError,
	ContactUsResetState,
	GetContactUsContent,
	GetContactUsContentSuccess,
} from './contact-us.actions';
import { catchError, tap } from 'rxjs/operators';
import produce from 'immer';

export class ContactUsStateModel {
	content: {
		title: string;
		introduction: string;
		thankYouMessage: string;
	};
	form: {
		model: any;
		dirty?: boolean;
		status?: string;
		errors?: object;
	};
	processing: boolean;
	success: boolean;
	error: any;
}

const stateDefaults = {
	content: {
		title: '',
		introduction: '',
		thankYouMessage: ''
	},
	form: {
		model: { message: '', subject: '' },
		dirty: false,
		status: '',
		errors: null,
	},
	processing: false,
	success: false,
	error: null,
};

@State<ContactUsStateModel>({
	name: 'contactUs',
	defaults: stateDefaults,
})
export class ContactUsState {
	constructor(private contactUsService: ContactUsService) {}

	@Selector()
	public static getContent(state: ContactUsStateModel) {
		return state.content;
	}

	@Selector()
	public static getSuccess(state: ContactUsStateModel) {
		return state.success;
	}

	@Selector()
	public static getError(state: ContactUsStateModel) {
		return state.error;
	}

	@Action(GetContactUsContent)
	onGetContactUsContent({ dispatch }: StateContext<ContactUsStateModel>) {
		this.contactUsService
			.getContactUsContent()
			.pipe(
				tap(content => {
					dispatch(new GetContactUsContentSuccess(content));
				})
			)
			.subscribe();
	}

	@Action(GetContactUsContentSuccess)
	onGetContactUsContentSuccess(
		{ patchState, getState }: StateContext<ContactUsStateModel>,
		{ content }: GetContactUsContentSuccess
	) {
		patchState(
			produce(getState(), draft => {
				draft.content.title = content.title;
				draft.content.introduction = content.introduction;
				draft.content.thankYouMessage = content.thankYouMessage;
			})
		);
	}

	@Action(SendContactUsMessage)
	onSendContactUsMessage(
		{ patchState, getState, dispatch }: StateContext<ContactUsStateModel>
	) {
		patchState(
			produce(getState(), draft => {
				draft.processing = true;
			})
		);

		const state = getState();
		const request = state.form.model;

		this.contactUsService
			.sendContactUsMessage(request)
			.pipe(
				tap(() => {
					dispatch(new SendContactUsMessageSuccess());
				}),
				catchError(err => dispatch(new SendContactUsMessageError(err)))
			)
			.subscribe();
	}

	@Action(SendContactUsMessageSuccess)
	onSendContactUsMessageSuccess({ patchState, getState }: StateContext<ContactUsStateModel>) {
		patchState(
			produce(getState(), draft => {
				draft.processing = false;
				draft.form.model.name = '';
				draft.form.model.email = '';
				draft.form.model.subject = '';
				draft.form.model.message = '';
				draft.form.dirty = false;
				draft.success = true;
			})
		);

		setTimeout(() => {
			patchState(
				produce(getState(), draft => {
					draft.success = false;
				})
			);
		}, 3000);
	}

	@Action(SendContactUsMessageError)
	onSendContactUsMessageError(
		{ patchState, getState }: StateContext<ContactUsStateModel>,
		{ error }: SendContactUsMessageError
	) {
		patchState(
			produce(getState(), draft => {
				draft.processing = false;
				draft.success = false;
				draft.error = error;
			})
		);
	}

	@Action(ContactUsResetState)
	onContactUsResetState({ setState }: StateContext<ContactUsStateModel>) {
		setState(stateDefaults);
	}
}
