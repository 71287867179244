import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-text-blog',
	templateUrl: './text-blog.component.html',
	styleUrls: ['./text-blog.component.scss'],
})
export class TextBlogComponent implements OnInit {
	routerLink: string;

	@Input()
	summary: any;

	constructor() { }

	ngOnInit() {
		this.routerLink = this.summary.manBlog ? '/man-blog' : '/blog';
	}
}
