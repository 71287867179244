import { Observable } from 'rxjs';
import { SettingsState } from './../../store/settings/settings.state';
import { SetCurrentPagerPage } from './../../store/settings/settings.actions';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Store, Select } from '@ngxs/store';

@Component({
	selector: 'app-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.scss'],
})
export class PagerComponent implements OnInit, OnChanges {
	numberOfPages: number;
	pages: number[];
	showNext: boolean;
	showPager: boolean;
	showPrevious: boolean;

	@Input()
	totalResults: number;
	@Input()
	pageSize: number;

	@Select(SettingsState.getCurrentPage)
	currentPage$: Observable<number>;
	currentPage: number;

	constructor(private store: Store) {}

	ngOnInit() {
		this.currentPage$.subscribe(currentPage => {
			this.currentPage = currentPage;
			this.showOrHideNextAndPrevious(currentPage);
		});
	}

	ngOnChanges() {
		this.numberOfPages = Math.ceil(this.totalResults / this.pageSize);
		this.pages = new Array<number>();

		for (let i = 1; i <= this.numberOfPages; i++) {
			this.pages.push(i);
		}

		this.goToPage(1);
		this.showOrHideNextAndPrevious(1);

		this.showPager = this.numberOfPages > 1;
	}

	goToPage(page) {
		this.store.dispatch(new SetCurrentPagerPage(page));
	}

	showOrHideNextAndPrevious(currentPage) {
		this.showPrevious = currentPage > 1;
		this.showNext = currentPage < this.numberOfPages;
	}

	goNext() {
		this.store.dispatch(new SetCurrentPagerPage(this.currentPage + 1));
	}

	goPrevious() {
		this.store.dispatch(new SetCurrentPagerPage(this.currentPage - 1));
	}
}
