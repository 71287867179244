import { Component, OnInit, ViewChild } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SiteState, GetSiteScaffold } from '../../../store';
import { Router } from '@angular/router';
@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
	@Select(SiteState.getMenu)
	menu$: Observable<Array<any>>;
	menuItems: any[];
	isCollapsed = true;
	message: string;

	constructor(private store: Store, private router: Router) {}

	ngOnInit() {
		this.store.dispatch(new GetSiteScaffold());

		this.menu$.subscribe(m => {
			this.menuItems = m;
		});
	}

	closeCollapse() {
		this.isCollapsed = true;
	}

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}
}
