import { BlogAuthorPageComponent } from './blogs/containers/blog-author-page/blog-author-page.component';
import { BlogPostPageComponent } from './blogs/containers/blog-post-page/blog-post-page.component';
import { BlogsPageComponent } from './blogs/containers/blogs-page/blogs-page.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home/containers/home-page/home-page.component';
import { SectionPageComponent } from './sections/containers/section-page/section-page.component';
import { MainComponent } from './layout/main/main.component';
import { SectionLayoutComponent } from './layout/section/section/section.layout.component';
import { SectionsPageComponent } from './sections/containers/sections-page/sections-page.component';
import { InfoPageContainerComponent } from './info-page/containers/info-page-container/info-page-container.component';
import { ContactUsPageComponent } from './contact-us/containers/contact-us-page/contact-us-page.component';
import { MansBlogsPageComponent } from './blogs/containers/man-blogs-page/man-blogs-page.component';
import { TestPageComponent } from './test-page/test-page.component';

const routes: Routes = [
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: HomePageComponent,
				data: { hideLogo: true },
			},
			{
				path: 'home',
				component: HomePageComponent,
				data: { hideLogo: true },
			},
			{
				path: '',
				component: SectionLayoutComponent,
				children: [
					{
						path: 'section',
						children: [
							{
								path: ':name',
								component: SectionPageComponent,
							},
						],
					},
					{
						path: 'sections/:name',
						component: SectionsPageComponent,
					},
				],
			},
			{
				path: 'blog',
				component: BlogsPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'man-blog',
				component: MansBlogsPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'blog/author/:name',
				component: BlogAuthorPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'blog/post/:name',
				component: BlogPostPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'man-blog/author/:name',
				component: BlogAuthorPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'man-blog/post/:name',
				component: BlogPostPageComponent,
				runGuardsAndResolvers: 'always',
			},
			{
				path: 'infopage/:name',
				component: InfoPageContainerComponent,
			},
			{
				path: 'contact-us',
				component: ContactUsPageComponent,
			}
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
