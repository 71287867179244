import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { States } from '../store';
import { HomePageComponent } from '../home/containers/home-page/home-page.component';
import { LinksComponent } from './components/links/links.component';
import { LinksGroupComponent } from './components/links-group/links-group.component';
import { LinksExpandedComponent } from './components/links-expanded/links-expanded.component';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { PagerComponent } from './components/pager/pager.component';
import { PopularBlogTagsComponent } from './components/popular-blog-tags/popular-blog-tags.component';
import { RegularAuthorsComponent } from './components/regular-authors/regular-authors.component';

@NgModule({
	imports: [CommonModule, NgxsModule.forRoot(States), RouterModule, FontAwesomeModule],
	providers: [],
	declarations: [
		HomePageComponent,
		LinksComponent,
		LinksGroupComponent,
		LinksExpandedComponent,
		HeroImageComponent,
		PagerComponent,
		PopularBlogTagsComponent,
		RegularAuthorsComponent
	],
	exports: [
		CommonModule,
		RouterModule,
		HomePageComponent,
		LinksComponent,
		LinksGroupComponent,
		HeroImageComponent,
		PagerComponent,
		PopularBlogTagsComponent,
		RegularAuthorsComponent
	],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
		};
	}
}
