import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Select } from '../../../../../node_modules/@ngxs/store';
import { SettingsState } from '../../../store';
import { Observable } from '../../../../../node_modules/rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Select(SettingsState.getLogo)
	logo$: Observable<string>;

	constructor() {}

	ngOnInit() {}
}
