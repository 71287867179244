import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Select } from '@ngxs/store';
import { SettingsState } from '../../../store';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-sub-sections',
	templateUrl: './sub-sections.component.html',
	styleUrls: ['./sub-sections.component.scss'],
})
export class SubSectionsComponent implements OnChanges {
	environment = environment;
	sections = new Array<any>();

	@Select(SettingsState.getSettings) settings$: Observable<any>;

	@Input() subSections: Array<any>;

	constructor() {}

	ngOnChanges(): void {
		if (!this.subSections) return;

		this.settings$.subscribe(settings => {
			this.sections = new Array<any>();

			this.subSections.forEach(sub => {
				const thumb = sub.thumbnail ? sub.thumbnail : settings.defaultSectionThumbnail;
				this.sections.push({
					title: sub.title,
					url: sub.url,
					style: {
						'background-image': `url('${thumb}')`,
					},
				});
			});
		});
	}
}
