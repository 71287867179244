import { BlogState } from './../blogs/store/blog.state';
import { ContactUsState } from './../contact-us/store/contact-us.state';
import { HomeState } from '../home/store/home.state';
import { SectionState } from '../sections/store/section.state';
import { SiteState } from '../shared/store/site/site.state';
import { SettingsState } from '../shared/store/settings/settings.state';
import { InfoPageState } from '../info-page/store/info-page.state';

export const States = [HomeState, SectionState, SiteState, SettingsState, InfoPageState, ContactUsState, BlogState];

export * from '../home/store/home.state';
export * from '../sections/store/section.state';
export * from '../shared/store/site/site.actions';
export * from '../shared/store/site/site.state';
export * from '../home/store/home.actions';
export * from '../sections/store/section.actions';
export * from '../shared/store/settings/settings.actions';
export * from '../shared/store/settings/settings.state';
export * from '../info-page/store/info-page.actions';
export * from '../info-page/store/info-page.state';
export * from './../contact-us/store/contact-us.actions';
export * from './../contact-us/store/contact-us.state';
export * from './../blogs/store/blog.actions';
export * from './../blogs/store/blog.state';
