import { GetSiteFooter } from './../../../shared/store/site/site.actions';
import { Component, OnInit, OnChanges } from '@angular/core';
import { Select, Store } from '../../../../../node_modules/@ngxs/store';
import { SiteState, SettingsState } from '../../../store';
import { Observable } from '../../../../../node_modules/rxjs';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnChanges {
	environment = environment;
	@Select(SettingsState.getEmail)
	email$: Observable<string>;
	@Select(SiteState.getFooter)
	footer$: Observable<any>;
	@Select(SettingsState.getLightLogo)
	logo$: Observable<string>;
	@Select(SettingsState.getSocialMedia)
	socialMedia$: Observable<any>;
	footer: any;

	constructor(private store: Store) {}

	ngOnInit() {
		this.store.dispatch(new GetSiteFooter());

		this.footer$.subscribe(footer => {
			this.footer = footer;
		});
	}

	ngOnChanges() {}
}
