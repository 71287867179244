import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetSettings, GetSettingsSuccess, SetCurrentPagerPage } from './settings.actions';
import { SettingsService } from '../../services/settings.service';
import produce from 'immer';
import { environment } from '../../../../environments/environment.prod';

export class SettingsStateModel {
	config: any;
	pager: {
		currentPage: Number;
	};
}

@State<SettingsStateModel>({
	name: 'settings',
	defaults: {
		config: null,
		pager: {
			currentPage: 1,
		},
	},
})
export class SettingsState {
	constructor(private settingsService: SettingsService) {}

	@Selector()
	static getSettings(state: SettingsStateModel) {
		return {
			defaultSectionThumbnail: state.config.defaultSectionThumbnail,
		};
	}

	@Selector()
	static getLogo(state: SettingsStateModel) {
		return state.config.logo;
	}

	@Selector()
	static getCurrentPage(state: SettingsStateModel) {
		return state.pager.currentPage;
	}

	@Selector()
	static getLightLogo(state: SettingsStateModel) {
		return state.config.lightLogo;
	}

	@Selector()
	static getSocialMedia(state: SettingsStateModel) {
		return state.config.socialMedia;
	}

	@Selector()
	static getEmail(state: SettingsStateModel) {
		return state.config.email;
	}

	@Action(GetSettings)
	onGetSettings({ dispatch }: StateContext<SettingsStateModel>) {
		this.settingsService.get().subscribe(settings => {
			dispatch(new GetSettingsSuccess(settings));
		});
	}

	@Action(GetSettingsSuccess)
	onGetSettingsSuccess({ patchState, getState }: StateContext<SettingsStateModel>, { settings }: GetSettingsSuccess) {
		patchState(
			produce(getState(), draft => {
				draft.config = settings;
			})
		);
	}

	@Action(SetCurrentPagerPage)
	onSetCurrentPagerPage({ patchState, getState }: StateContext<SettingsStateModel>, { page }: SetCurrentPagerPage) {
		patchState(
			produce(getState(), draft => {
				draft.pager.currentPage = page;
			})
		);
	}
}
