import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-footer-links',
	templateUrl: './footer-links.component.html',
	styleUrls: ['./footer-links.component.scss'],
})
export class FooterLinksComponent implements OnInit {
	@Input() links: Observable<Array<any>>;

	constructor() {}

	ngOnInit() {}
}
