import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetSection, GetSectionSuccess } from './section.actions';
import { SectionService } from '../services/section.service';
import produce from 'immer';

export class SectionStateModel {
	title: string;
	aBitOfBlurb: string;
	image: string;
	linkGroups: Array<any>;
	subSections: Array<any>;
	positionX: string;
	positionY: string;
	template: string;
}

@State<SectionStateModel>({
	name: 'section',
	defaults: {
		title: '',
		aBitOfBlurb: '',
		image: '',
		linkGroups: null,
		subSections: null,
		positionX: '50%',
		positionY: '0',
		template: '',
	},
})
export class SectionState {
	constructor(private sectionService: SectionService) {}

	@Selector()
	static getSection(state: SectionStateModel) {
		return state;
	}

	@Selector()
	static getSectionTemplate(state: SectionStateModel) {
		return state.template;
	}

	@Action(GetSection)
	onGetSection({ dispatch }: StateContext<SectionStateModel>, { name, type }: GetSection) {
		this.sectionService.get(name, type).subscribe(section => {
			dispatch(new GetSectionSuccess(section));
		});
	}

	@Action(GetSectionSuccess)
	onGetSectionSuccess({ setState }: StateContext<SectionStateModel>, { section }: GetSectionSuccess) {
		setState({
			title: section.title,
			aBitOfBlurb: section.aBitOfBlurb,
			image: section.image,
			linkGroups: section.linkGroups,
			subSections: section.subSections,
			positionX: section.positionX,
			positionY: section.positionY,
			template: section.template,
		});
	}
}
