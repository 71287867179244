import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ContactUsService {
	constructor(private restangular: Restangular) {}

	sendContactUsMessage(request: any): Observable<any> {
		return this.restangular.one('contact-us').post(null, request);
	}

	getContactUsContent(): Observable<any> {
		return this.restangular.one('contact-us').get();
	}
}
