import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Component({
	selector: 'app-test-page',
	templateUrl: './test-page.component.html',
	styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent implements OnInit {

	constructor(
		private http: HttpClient
	) { }

	ngOnInit() {
	}
}
