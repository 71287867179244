import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { RestangularModule } from 'ngx-restangular';
import { Store } from '@ngxs/store';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { SectionPageComponent } from './sections/containers/section-page/section-page.component';
import { environment } from '../environments/environment';
import { SectionsPageComponent } from './sections/containers/sections-page/sections-page.component';
import { SubSectionsComponent } from './sections/components/sub-sections/sub-sections.component';
import { DisqusModule } from 'ngx-disqus';
import { InfoPageContainerComponent } from './info-page/containers/info-page-container/info-page-container.component';
import { ContactUsPageComponent } from './contact-us/containers/contact-us-page/contact-us-page.component';
import { ContactUsFormComponent } from './contact-us/components/contact-us-form/contact-us-form.component';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlogsPageComponent } from './blogs/containers/blogs-page/blogs-page.component';
import { BlogListComponent } from './blogs/components/blog-list/blog-list.component';
import { TextBlogComponent } from './blogs/components/text-blog/text-blog.component';
import { ImageBlogComponent } from './blogs/components/image-blog/image-blog.component';
import { BlogPostPageComponent } from './blogs/containers/blog-post-page/blog-post-page.component';
import { BlogTagsComponent } from './blogs/components/blog-tags/blog-tags.component';
import { Angulartics2Module } from 'angulartics2';
import { CookieLawModule } from 'angular2-cookie-law';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlogAuthorPageComponent } from './blogs/containers/blog-author-page/blog-author-page.component';
import { BlogAuthorComponent } from './blogs/components/blog-author/blog-author.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MansBlogsPageComponent } from './blogs/containers/man-blogs-page/man-blogs-page.component';
import { TestPageComponent } from './test-page/test-page.component';

export function RestangularConfigFactory(RestangularProvider) {
	RestangularProvider.setBaseUrl(environment.api);
}

@NgModule({
	declarations: [
		AppComponent,
		SectionPageComponent,
		SectionsPageComponent,
		SubSectionsComponent,
		InfoPageContainerComponent,
		ContactUsPageComponent,
		ContactUsFormComponent,
		BlogsPageComponent,
		MansBlogsPageComponent,
		BlogListComponent,
		TextBlogComponent,
		ImageBlogComponent,
		BlogPostPageComponent,
		BlogTagsComponent,
		BlogAuthorPageComponent,
		BlogAuthorComponent,
		TestPageComponent,
	],
	imports: [
		BrowserModule,
		SharedModule,
		LayoutModule,
		AppRoutingModule,
		NgxsReduxDevtoolsPluginModule.forRoot(),
		NgxsRouterPluginModule.forRoot(),
		RestangularModule.forRoot([Store], RestangularConfigFactory),
		DisqusModule.forRoot('marbleslostandfound'),
		NgxsFormPluginModule.forRoot(),
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		Angulartics2Module.forRoot(),
		CookieLawModule,
		BrowserAnimationsModule,
		FontAwesomeModule
	],
	exports: [
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
