export class GetSettings {
	static readonly type = '[Settings] Get';
}

export class GetSettingsSuccess {
	static readonly type = '[Settings] Get Settings Success';
	constructor(public readonly settings: any) {}
}

export class SetCurrentPagerPage {
	static readonly type = '[Settings] Set Current Pager Page';
	constructor(public readonly page: number) {}
}
