import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { Component, OnInit, Input } from '@angular/core';
import { SectionState } from '../../../store';

@Component({
	selector: 'app-links-expanded',
	templateUrl: './links-expanded.component.html',
	styleUrls: ['./links-expanded.component.scss'],
})
export class LinksExpandedComponent implements OnInit {
	@Input()
	links: Array<any>;
	constructor() {}

	ngOnInit() {}
}
