import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SiteService } from '../../services/site.service';
import produce from 'immer';
import { GetSiteScaffoldSuccess, GetSiteScaffold, GetSiteFooter, GetSiteFooterSuccess } from './site.actions';
import * as moment from 'moment';

export class SiteStateModel {
	scaffold: any;
	footer: any;
	loaded: boolean;
}

@State<SiteStateModel>({
	name: 'site',
	defaults: {
		scaffold: null,
		footer: {},
		loaded: false
	}
})
export class SiteState {
	constructor(private siteService: SiteService) {}

	@Selector()
	static getMenu(state: SiteStateModel) {
		return state.scaffold.menu;
	}

	@Selector()
	static getFooter(state: SiteStateModel) {
		return state.footer;
	}

	@Action(GetSiteScaffold)
	onGetMenu({ dispatch }: StateContext<SiteStateModel>) {
		this.siteService.getMenu().subscribe(menu => {
			dispatch(new GetSiteScaffoldSuccess(menu));
		});
	}

	@Action(GetSiteScaffoldSuccess)
	onGetSiteScaffoldSuccess(
		{ patchState, getState }: StateContext<SiteStateModel>,
		{ siteScaffold }: GetSiteScaffoldSuccess
	) {
		patchState(
			produce(getState(), draft => {
				draft.scaffold = siteScaffold;
				draft.loaded = true;
			})
		);
	}

	@Action(GetSiteFooter)
	onGetSiteFooter({ dispatch }: StateContext<SiteStateModel>) {
		this.siteService.getFooter().subscribe(footer => {
			dispatch(new GetSiteFooterSuccess(footer));
		});
	}

	@Action(GetSiteFooterSuccess)
	onGetSiteFooterSuccess({ patchState, getState }: StateContext<SiteStateModel>, { footer }: GetSiteFooterSuccess) {
		const blogs = footer.blogs.map(blog => {
			return {
				...blog,
				date: moment(blog.publishedDate).format('DD MMM, YYYY'),
			};
		});

		patchState(
			produce(getState(), draft => {
				draft.footer.links = footer.links;
				draft.footer.blogs = blogs;
			})
		);
	}
}
