import { Restangular } from 'ngx-restangular';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BlogService {
	constructor(private restangular: Restangular) {}

	getBlogList(): Observable<Array<any>> {
		return this.restangular.one('blog').get();
	}

	getManBlogList(): Observable<Array<any>> {
		return this.restangular.one('man-blog').get();
	}

	getBlogPost(name: string): Observable<any> {
		return this.restangular.one('blog', name).get();
	}

	getBlogAuthor(name: string): Observable<any> {
		return this.restangular.one('blog').one('author', name).get();
	}

	getBlogTags(blogs: any) {
		const arr = new Array<any>();

		if (!blogs) return;

		const tags = blogs
			.map(blog => {
				return blog.tags;
			})
			.reduce((a, b) => {
				return a.concat(b);
			})
			.reduce((prev, curr) => {
				const t = arr.find(i => i.tag === curr);

				if (!t) {
					arr.push({ tag: curr, count: 1 });
				} else {
					t.count++;
				}

				return arr;
			})
			.sort((a, b) => {
				return b.count - a.count;
			});

		const spliced = tags.length > 25 ? tags.splice(0, 25) : tags;

		return spliced.map(item => {
			return item.tag;
		});
	}
}
