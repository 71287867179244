import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-image-blog',
	templateUrl: './image-blog.component.html',
	styleUrls: ['./image-blog.component.scss'],
})
export class ImageBlogComponent implements OnInit {
	routerLink: string;

	@Input()
	summary: any;

	constructor() { }

	ngOnInit() {
		this.routerLink = this.summary.manBlog ? '/man-blog' : '/blog';
	}
}
