import { HomeContent } from '../../interfaces/home-content.interface';

export class GetHomeContent {
	static readonly type = '[Home] Get Content';
}

export class GetHomeContentSuccess {
	static readonly type = '[Home] Get Home Content Success';
	constructor(public readonly homeContent: HomeContent) {}
}
