import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetHomeContent, HomeState } from '../../../store';
import { HomeContent } from '../../../interfaces/home-content.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-home-page',
	templateUrl: './home-page.component.html',
	styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
	@Select(HomeState.getHomeContent)
	content$: Observable<HomeContent>;
	content: any;
	image: string;
	styles: { 'background-image': string; 'background-position': string };
	centralImageStyles: { 'background-image': string };

	constructor(private store: Store, private titleService: Title) {}

	ngOnInit() {
		this.titleService.setTitle('Home');
		this.store.dispatch(new GetHomeContent());

		this.content$.subscribe(content => {
			if (!content) return;
			this.content = content;
			this.styles = {
				'background-image': content.image ? 'url(' + content.image + ')' : '',
				'background-position': '0 0',
			};
			this.centralImageStyles = {
				'background-image': 'url(' + content.centralImage + ')',
			};
		});
	}
}
