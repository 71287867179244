import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	constructor(private restangular: Restangular) {}

	get(): any {
		return this.restangular.one('settings').get();
	}
}
