import { MarblesTitleService } from './../../../shared/services/title.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { GetSection } from '../../store/section.actions';
import { SectionState } from '../../store/section.state';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-section-page',
	templateUrl: './section-page.component.html',
	styleUrls: ['./section-page.component.scss'],
})
export class SectionPageComponent implements OnInit {
	sectionName: string;
	section: any;

	@Select(SectionState.getSection) section$: any;
	bob: any;

	constructor(private route: ActivatedRoute, private store: Store,
		private sanitizer: DomSanitizer, private titleService: Title, private marblesTitleService: MarblesTitleService) { }

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.sectionName = params.get('name');

			this.store.dispatch(new GetSection(this.sectionName, 'section'));

			this.section$.subscribe(section => {
				this.titleService.setTitle(this.marblesTitleService.strip(section.title));
				this.bob = this.sanitizer.bypassSecurityTrustHtml(section.aBitOfBlurb);
				this.section = section;
			});
		});
	}
}
