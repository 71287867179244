export class GetBlogList {
	public static type = '[Blog] Get List';
}

export class GetBlogListSuccess {
	public static type = '[Blog] Get List Success';
	constructor(public readonly blogs: any) { }
}

export class GetBlogPost {
	public static type = '[Blog] Get Post';
	constructor(public readonly name: string) { }
}

export class GetBlogPostSuccess {
	public static type = '[Blog] Get Post Success';
	constructor(public readonly blogPost: any) { }
}

export class GetBlogAuthor {
	public static type = '[Blog] Get Author';
	constructor(public readonly name: string) { }
}

export class GetBlogAuthorSuccess {
	public static type = '[Blog] Get Author Success';
	constructor(public readonly author: any) { }
}

export class SetBlogFilter {
	public static type = '[Blog] Set Filter';
	constructor(public readonly filterType: string, public readonly filter: string) { }
}
