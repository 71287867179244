import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
import { FooterComponent } from './footer/containers/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main/main.component';
import { MenuComponent } from './menu/components/menu.component';
import { HeaderComponent } from './header/header/header.component';
import { SectionLayoutComponent } from './section/section/section.layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterLinksComponent } from './footer/components/footer-links/footer-links.component';
import { FooterBlogComponent } from './footer/components/footer-blog/footer-blog.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BsDropdownModule, CollapseModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: true,
	useBothWheelAxes: true,
};

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		NgxTwitterTimelineModule.forRoot(),
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		FontAwesomeModule
	],
	declarations: [
		MainComponent,
		MenuComponent,
		HeaderComponent,
		FooterComponent,
		SectionLayoutComponent,
		SidebarComponent,
		FooterLinksComponent,
		FooterBlogComponent,
	],
	exports: [MainComponent, MenuComponent, SidebarComponent],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
	],
})
export class LayoutModule {}
