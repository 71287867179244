import { GetBlogList } from './../../store/blog.actions';
import { SettingsState } from './../../../shared/store/settings/settings.state';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { BlogState } from 'src/app/store';

@Component({
	selector: 'app-blog-list',
	templateUrl: './blog-list.component.html',
	styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit, OnChanges {
	allSummaries: any[];
	filteredSummaries: any[];
	isAuthorPage: boolean;
	pageSize: number;
	pageTitle: string;
	summaries: any[];
	tag: any;
	totalResults: number;

	@Select(BlogState.getSummaries)
	summaries$: Observable<any>;
	@Select(SettingsState.getCurrentPage)
	currentPage$: Observable<number>;

	@Input() man: boolean;

	constructor(private store: Store, private route: ActivatedRoute) { }

	ngOnInit() {
		this.store.dispatch(new GetBlogList());

		this.pageSize = 5;
		this.tag = '';
		this.summaries$.subscribe(summariesObj => {
			const summaries = this.man ? summariesObj.manBlogs : summariesObj.ladyBlogs;

			this.pageTitle = this.man ? 'Man Blog' : 'Warrior Blog';

			if (!summaries || summaries.length === 0) return;

			this.isAuthorPage = false;
			this.allSummaries = summaries;
			this.summaries = summaries;

			this.currentPage$.subscribe(currentPage => {
				this.takeSlice(currentPage, this.filteredSummaries ? this.filteredSummaries : this.allSummaries);
			});

			const obsCombined = combineLatest(this.route.params, this.route.queryParams, (params, qparams) => ({ params, qparams }));
			obsCombined.subscribe(ap => {
				const author = ap.params['name'];
				const tag = ap.qparams['tag'];

				if (author || tag) {
					if (tag) {
						this.filteredSummaries = this.allSummaries.filter(summary => {
							return summary.tags.indexOf(tag) > -1;
						});

						this.isAuthorPage = false;
					}

					if (author) {
						this.filteredSummaries = this.allSummaries.filter(summary => {
							return summary.authorLink && summary.authorLink.indexOf(author) > -1;
						});

						this.isAuthorPage = true;
					}

					this.totalResults = this.filteredSummaries.length;
					this.takeSlice(1, this.filteredSummaries);

				} else {
					this.isAuthorPage = false;
					this.summaries = this.allSummaries;
					this.filteredSummaries = undefined;
					this.totalResults = this.allSummaries.length;
					this.takeSlice(1, this.allSummaries);
				}
			});
		});
	}

	takeSlice(page, collection) {
		page = page - 1;
		const sliceFrom = this.pageSize * page;
		const to = this.pageSize > collection.length ? collection.length : this.pageSize;
		this.summaries = collection.slice(sliceFrom, sliceFrom + to);
	}

	ngOnChanges() { }
}
