export class GetSiteScaffold {
	static readonly type = '[Site] Get Site Scaffold';
}

export class GetSiteFooter {
	static readonly type = '[Site] Get Site Footer';
}

export class GetSiteScaffoldSuccess {
	static readonly type = '[Site] Get Site Scaffold Success';
	constructor(public readonly siteScaffold: Array<any>) {}
}

export class GetSiteFooterSuccess {
	static readonly type = '[Site] Get Site Footer Success';
	constructor(public readonly footer: any) {}
}
