import { Observable } from 'rxjs';
import { ContactUsState } from './../../store/contact-us.state';
import { FormService } from '../../../shared/services/form.service';
import { Store, Select } from '@ngxs/store';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SendContactUsMessage } from '../../../store';

@Component({
	selector: 'app-contact-us-form',
	templateUrl: './contact-us-form.component.html',
	styleUrls: ['./contact-us-form.component.scss'],
})
export class ContactUsFormComponent implements OnInit {
	contactUsForm: FormGroup;
	success: boolean;

	@Input()
	thankYouMessage: string;

	@Select(ContactUsState.getSuccess)
	success$: Observable<boolean>;

	constructor(private formBuilder: FormBuilder, private store: Store, private formService: FormService) {
		this.contactUsForm = formBuilder.group({
			name: [null, Validators.compose([Validators.required])],
			email: [null, Validators.compose([Validators.required, Validators.email])],
			subject: [null],
			message: [null, Validators.compose([Validators.required])],
		});
	}

	ngOnInit() {
		this.success$.subscribe(s => {
			this.success = s;
		});
	}

	contactUs() {
		if (!this.contactUsForm.valid) {
			this.formService.markFieldsAsTouched(this.contactUsForm);
			return;
		}

		this.store.dispatch(new SendContactUsMessage());
		this.contactUsForm.reset();
	}
}
