import { CommonModule } from '@angular/common';
import { GetInfoPage, GetInfoPageSuccess } from './info-page.actions';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { InfoPageService } from '../services/info-page.service';
import produce from 'immer';

export class InfoPageStateModel {
	content: string;
	title: string;
}

@State<InfoPageStateModel>({
	name: 'page',
	defaults: {
		content: '',
		title: ''
	},
})
export class InfoPageState {
	constructor(private infoPageService: InfoPageService) {}

	@Selector()
	static getPageContent(state: InfoPageStateModel) {
		return state;
	}

	@Action(GetInfoPage)
	onGetInfoPage({ dispatch }: StateContext<InfoPageStateModel>, { name }: GetInfoPage) {
		this.infoPageService.get(name).subscribe(content => {
			dispatch(new GetInfoPageSuccess(content));
		});
	}

	@Action(GetInfoPageSuccess)
	onGetInfoPageSuccess(
		{ patchState, getState }: StateContext<InfoPageStateModel>,
		{ infoPage }: GetInfoPageSuccess
	) {
		patchState(
			produce(getState(), draft => {
				draft.content = infoPage.content;
				draft.title = infoPage.title;
			})
		);
	}
}
