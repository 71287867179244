import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-blog-tags',
	templateUrl: './blog-tags.component.html',
	styleUrls: ['./blog-tags.component.scss'],
})
export class BlogTagsComponent implements OnInit {
	routerLink: string;

	@Input() man: boolean;
	@Input() tags: Array<string>;

	constructor() {
	}

	ngOnInit() {
		this.routerLink = this.man ? '/man-blog' : '/blog';
	}
}
