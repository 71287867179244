import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { SectionState } from '../../../store';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-section',
	templateUrl: './section.layout.component.html',
	styleUrls: ['./section.layout.component.scss'],
})
export class SectionLayoutComponent implements OnInit {
	@Select(SectionState.getSection)
	section$: any;
	section: any;
	styles: { 'background-image': string; 'background-position': string };

	constructor() {}

	ngOnInit() {
		this.section$.subscribe(s => {
			this.section = s;

			this.styles = {
				'background-image': this.section.image ? 'url(' + this.section.image + ')' : '',
				'background-position': `${this.section.positionX} ${this.section.positionY}`
			};
		});
	}
}
