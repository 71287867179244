import { Observable } from 'rxjs';
import { GetInfoPage } from './../../store/info-page.actions';
import { DomSanitizer } from '@angular/platform-browser';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { InfoPageState } from '../../store/info-page.state';

@Component({
	selector: 'app-info-page-container',
	templateUrl: './info-page-container.component.html',
	styleUrls: ['./info-page-container.component.scss'],
})
export class InfoPageContainerComponent implements OnInit {
	pageName: string;
	content: string;
	title: string;

	@Select(InfoPageState.getPageContent)
	content$: Observable<any>;

	constructor(private route: ActivatedRoute, private store: Store, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.pageName = params.get('name');

			this.store.dispatch(new GetInfoPage(this.pageName));

			this.content$.subscribe(page => {
				this.content = page.content;
				this.title = page.title;
			});
		});
	}
}
