import { BlogState } from 'src/app/store';
import { Select } from '@ngxs/store';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-popular-blog-tags',
	templateUrl: './popular-blog-tags.component.html',
	styleUrls: ['./popular-blog-tags.component.scss'],
})
export class PopularBlogTagsComponent implements OnInit {
	tags: string[];
	routerLink: string;

	@Select(BlogState.getTags)
	tags$: Observable<Array<string>>;
	@Select(BlogState.getManTags)
	manTags$: Observable<Array<string>>;

	@Input() man: boolean;

	constructor() { }

	ngOnInit() {
		this.routerLink = this.man ? '/man-blog' : '/blog';

		if (!this.man) {
			this.tags$.subscribe(tags => {
				this.tags = tags;
			});
		} else {
			this.manTags$.subscribe(tags => {
				this.tags = tags;
			});
		}
	}
}
