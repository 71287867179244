import { Observable } from 'rxjs';
import { SettingsState } from './shared/store/settings/settings.state';
import { Component, OnInit } from '@angular/core';
import { Store, Select } from '../../node_modules/@ngxs/store';
import { GetSettings } from './store';
import { Router, NavigationEnd, NavigationStart } from '../../node_modules/@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { IconHelper } from './shared/utils/icon-helper';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	icons = IconHelper.Icons;
	hasLoaded = false;
	loaded = true;
	title = 'Marbles Lost & Found';

	@Select(SettingsState.getLogo)
	logo$: Observable<string>;

	constructor(
		private store: Store,
		private router: Router,
		private titleService: Title
	) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			if (!this.hasLoaded) {
				this.loaded = false;
			}
		}, 100);

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				(<any>window).gtag('config', 'UA-128851398-1', {
					'page_title': this.titleService.getTitle(),
					'page_path': event.urlAfterRedirects
				});
			}
		});

		this.store.dispatch(new GetSettings());

		this.router.events.subscribe(evt => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});

		this.store
			.select(state => state.site.loaded)
			.subscribe(loaded => {
				if (loaded) {
					this.loaded = loaded;
					this.hasLoaded = true;
				}
			});
	}
}
