import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';

@Injectable({
	providedIn: 'root',
})
export class SectionService {
	constructor(private restangular: Restangular) {}

	get(name: string, type: string): any {
		return this.restangular.one('section', name).one(type).get();
	}
}
