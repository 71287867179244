import { Injectable } from '@angular/core';
import { Restangular } from 'ngx-restangular';
import { Observable } from 'rxjs';
import { HomeContent } from '../../interfaces/home-content.interface';

@Injectable({
	providedIn: 'root',
})
export class HomeService {
	constructor(private restangular: Restangular) {}

	getHomeContent(): Observable<HomeContent> {
		return this.restangular.one('home').get();
	}
}
