import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MarblesTitleService {
	strip(html) {
		const doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || '';
	}
}
