import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-footer-blog',
	templateUrl: './footer-blog.component.html',
	styleUrls: ['./footer-blog.component.scss'],
})
export class FooterBlogComponent implements OnInit {
	@Input() blogs: any;

	constructor() {}

	ngOnInit() {}
}
