import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FormService {
	constructor() {}

	markFieldsAsTouched(form) {
		Object.keys(form['controls']).forEach(field => {
			const control = form.get(field);
			control.markAsTouched({ onlySelf: true });
		});
	}
}
