import { IconHelper } from './../../utils/icon-helper';
import { BlogState } from 'src/app/store';
import { Component, OnInit, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-regular-authors',
	templateUrl: './regular-authors.component.html',
	styleUrls: ['./regular-authors.component.scss']
})
export class RegularAuthorsComponent implements OnInit {
	authors: Array<any>;
	icons = IconHelper.Icons;

	@Select(BlogState.getRegularAuthors) authors$: Observable<any>;

	@Input() man: boolean;

	constructor() { }

	ngOnInit() {

		this.authors$.subscribe(authors => {
			if (!this.man) {
				this.authors = authors.ladyAuthors;
			} else {
				this.authors = authors.manAuthors;
			}
		});
	}
}
