import { DomSanitizer, Title } from '@angular/platform-browser';
import { GetBlogPost, GetBlogList } from './../../store/blog.actions';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { BlogState } from 'src/app/store';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-blog-post-page',
	templateUrl: './blog-post-page.component.html',
	styleUrls: ['./blog-post-page.component.scss'],
})
export class BlogPostPageComponent implements OnInit {
	content: any;

	@Select(BlogState.getSelectedBlogPost)
	post$: Observable<any>;
	post: any;

	constructor(private route: ActivatedRoute, private store: Store, private sanitizer: DomSanitizer, private titleService: Title) {}

	ngOnInit() {
		this.store.dispatch(new GetBlogList());

		this.route.paramMap.subscribe(params => {
			const blogName = params.get('name');
			this.store.dispatch(new GetBlogPost(blogName));
		});

		this.post$.subscribe(post => {
			if (!post) return;
			this.titleService.setTitle(post.title);
			this.post = post;
			this.content = this.sanitizer.bypassSecurityTrustHtml(post.content);
		});
	}
}
