import { Observable } from 'rxjs';
import { SectionState } from './../../../sections/store/section.state';
import { Select } from '@ngxs/store';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-links-group',
	templateUrl: './links-group.component.html',
	styleUrls: ['./links-group.component.scss'],
})
export class LinksGroupComponent implements OnInit {
	@Input() linkGroups: Array<any>;
	@Select(SectionState.getSectionTemplate)
	template$: Observable<string>;

	constructor() {}

	ngOnInit() {}
}
